import {
	xData
} from "../utils/cardXData.js"

export default {
	name: "CgmGuardianRt",
	data() {
		return {
			chartInstance: null,
			chartInstances: null,
			xAxisData: [],
			xAxisDataAll: [],
			yAxisData: [],
			realtime: '',
			oneDayData: [],
			allMinutes: []
		}
	},
	props: {
		puid: String,
		yDataList: [],
	},
	mounted() {
		this.getxAxisDataAll();
		this.getxAxisData();
		this.getyAxisData();
		this.initchart();
	},
	methods: {
		//x轴整体数据
		getxAxisDataAll() {
			var xAxisData = [];
			var xMinute = [];
			var xHour = [];
			for (var min = 0; min < 60; min++) {
				var setMin = min + '';
				if (setMin < 10) {
					setMin = '0' + setMin
				}
				xMinute.push(setMin)
			}
			for (var hour = 0; hour < 24; hour++) {
				var setHour = hour + '';
				if (setHour < 10) {
					setHour = '0' + setHour
				}
				xHour.push(setHour);
			}
			this.xAxisDataAll = xAxisData;
			//console.log(this.xAxisDataAll)
		},
		getxAxisData() {
			let newDate = new Date();
			let yy = newDate.getFullYear();
			let mm = newDate.getMonth() + 1;
			mm = mm < 10 ? '0' + mm : mm;
			let dd = newDate.getDate();
			dd = dd < 10 ? '0' + dd : dd;

			let forCal = new Date(yy + '-' + mm + '-' + dd + ' 00:00:00');
			let t_s = forCal.getTime();

			for (let i = 0; i < 1440; i++) {
				forCal.setTime(t_s + 1000 * 60 * i);

				let newHour = forCal.getHours() < 10 ? '0' + forCal.getHours() : forCal.getHours();
				let newMin = forCal.getMinutes() < 10 ? '0' + forCal.getMinutes() : forCal.getMinutes();
				let newtime = yy + '-' + mm + '-' + dd + ' ' + newHour + ':' + newMin + ':00';
				this.allMinutes.push(newtime);
			}
		},
		getyAxisData() {
			let arr = [];
			if (this.yDataList !== null) {
				this.allMinutes.forEach(iii => {
					let isHas = false;
					this.yDataList.forEach(item => {
						if (iii === item.measureTime) {
							arr.push(item.glucose);
							isHas = true;
						}
					});
					if (!isHas) {
						arr.push('N/A');
					}
				});
				this.oneDayData = arr;
			} else {
				this.oneDayData = []
			}

		},
		//初始化echartInstance对象
		initchart() {
			this.chartInstance = this.$echarts.init(this.$refs.seller_ref);
			const initOption = {
				tooltip: {
					trigger: 'axis'
				},
				grid: {
					top: 20,
					left: 0,
					bottom: 0,
					right: 24,
					containLabel: true
				},
				xAxis: {
					type: 'category',
					data: xData,
					boundaryGap: false,
					axisLabel: {
						interval: 0
					},
					axisTick: {
						interval: 59
					}
				},
				yAxis: {
					type: 'value',
					//y轴最大值最小值
					min: 2,
					max: 15,
					scale: true,
					show: false
				},
				//线的颜色样式
				visualMap: {
					top: 500,
					type: 'piecewise',
					//symbolSize: 80,
					pieces: [{
						gt: 2,
						lte: 3.9,
						color: '#f33131',
					}, {
						gt: 3.9,
						lte: 10,
						color: '#494949',

					}, {
						gt: 10,
						lte: 25,
						color: '#fdae61',
					}],
				},
				series: [{
					name: '',
					color: '#22c9e4',
					top: 0,
					data: this.oneDayData,
					//线形
					type: 'line',
					//平滑的曲线
					smooth: true,
					//是否显示节点的○符号
					showSymbol: false,
					lineStyle: {
						width: 2
					},
					markArea: { //标记区域
						data: [
							[{
								yAxis: '3.9',
								itemStyle: {
									color: '#fffb8723'
								}
							}, {
								yAxis: '10'
							}]
						]
					},
					//分割线
					/* markLine: {
						symbolSize: [8, 14],
						silent: true,
						data: [{
							yAxis: 3.9,
							lineStyle: {
								color: '#f33131',
								width: 1.5,
								//type: 'solid'
							},
						}, {
							yAxis: 10,
							lineStyle: {
								color: '#f1b907',
								width: 1.5,
								//type: 'solid'
							},
						}]
					}, */
				}],
			};
			this.chartInstance.setOption(initOption)
		}
	},
}
