import { xData } from "../../utils/chartXData.js"
import CgmMonitor from '../../components/CgmMonitor.vue'
import redalarm from '../../assets/images/redalarm.png'
import grayalarm from '../../assets/images/grayalarm.png'
import greedalarm from '../../assets/images/greedalarm.png'
import yellowalarm from '../../assets/images/yellowalarm.png'

export default {
	name: "PatientMonitor",
	components: {
		CgmMonitor: CgmMonitor,
	},
	data() {
		return {
			loading: false,
			xData:xData,
			DataList:null,
			tableData:[],
			carddata:{
				page: 1,
				size: 8,
			},
			redalarm:redalarm,
			grayalarm:grayalarm,
			greedalarm:greedalarm,
			yellowalarm:yellowalarm,
			total: 0,
			timer:null,//定时器名称
			showtableData:false,
			timerKey: ''
		}
	},
	created() {
		this.getPatientMonitor();
	},
	mounted() {
		//定时器
		this.timer = setInterval(() =>{
			this.getPatientMonitor();
			this.timerKey = new Date().getTime();
		},1000*180)
	},
	methods: {
		//card 跳转页面
		doGlucometer(userUuid) {
			console.log(userUuid);
			this.$router.push({
				path: 'patient-detail',
				query: {
					getPatientDetailUuid: userUuid,
					userType: 1,
					activeName:'third'
				}
			});
		},
		// 监听 pagesize 改变的事件
		handleSizeChange(newSize) {
			this.carddata.size = newSize;
			this.getPatientMonitor()
		},
		//监听 页码值 改变的事件
		handleCurrentChange(newPage) {
			this.carddata.page = newPage;
			this.getPatientMonitor()
		},
		getPatientMonitor(){
			debugger
			this.loading = true;
			this.carddata.userType = parseInt(sessionStorage.getItem('userType'));
			this.carddata.userUuid = sessionStorage.getItem('userUuid');
			console.log(this.carddata)
			this.$axios({
				method: "post",
				url: "/api/sys/web/patient/page",
				data:this.carddata,
			}).then(res => {
				if (res.data.code === 200) {
					this.tableData = res.data.data;
					for(var i=0;i<this.tableData.length;i++){
						var mobile = this.tableData[i].mobile;
						if(mobile){
							mobile = mobile.substring(0,3) + "****" + mobile.substring(7,12);
							this.tableData[i].mobile= mobile;
						}
						var name = this.tableData[i].name;
						if(name){
							if(name.length>2){
								name = name.substring(0,1) + "***" + name.substring(name.length-1,name.length);
							}else{
								name = name.substring(0,1) + "*";
							}
							this.tableData[i].name = name;
						}
					}
					if(this.tableData.length !== 0){
						this.total = res.data.pageData.totalElements;
						let arr = [];
						res.data.data.forEach(item => {
							arr.push(item.list);
						});
						this.DataList = arr;
					}else{
						this.showtableData=true
					}
				} else {
					this.$message({
						message: "操作失败",
						type: "error",
					});
				}
				this.loading = false;
				return
			}).catch(error => {
				console.log(error);
				//this.$message.error("请求失败");
				this.loading = false;
			});
		},
	},
	beforeDestroy(){
		clearInterval(this.timer);
		this.timer = null;
	}
}
